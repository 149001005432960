@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SpaceGrotesk';
  src: local('SpaceGrotesk'), url(./fonts/otf/SpaceGrotesk-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 500;
  src: local('SpaceGrotesk'), url(./fonts/otf/SpaceGrotesk-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 700;
  src: local('SpaceGrotesk'), url(./fonts/otf/SpaceGrotesk-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 300;
  src: local('SpaceGrotesk'), url(./fonts/otf/SpaceGrotesk-Light.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'SpaceGrotesk', sans-serif;
  font-feature-settings: 'salt', 'ss01', 'ss04';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: 'JetBrains Mono', monospace;
}

.text-gradient,
.hover-gradient:hover {
  background: linear-gradient(89.2deg, #f58342 0.18%, #f5326d 94.89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.underline-gradient::after,
.underline-gradient::before,
.underline-gradient:hover,
.underline-gradient-no-hover::after {
  content: '';
  background: linear-gradient(89.2deg, #f58342 0.18%, #f5326d 94.89%);
  border-radius: 8px;
}

.linenumber {
  min-width: 1.5rem !important;
}

.hover-magenta-lighter-20:hover {
  filter: brightness(120%);
}
